






















import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { LoadingStatusType } from "@/enums/enums";
import { DeleteLatestUpdateRequestModel, LatestUpdateTableViewModel } from "@/model/latestUpdate/latestUpdateModel";

const StoreLatestUpdate = namespace("LatestUpdate");
@Component
export default class ModalDeleteLatestUpdate extends Vue {
  @Prop({ required: true }) readonly code!: any;
  @Prop({ required: true }) readonly name!: any;
  private isShowModal = true;
  @StoreLatestUpdate.Action
  private DoDeleteBanner!: (input: DeleteLatestUpdateRequestModel) => void;
  @StoreLatestUpdate.Action
  private DoGetBannerTable!: (latestUpdateTableViewModel: LatestUpdateTableViewModel) => void;
  @StoreLatestUpdate.Action
  private getDeleteBannerLoadingStatus!: LoadingStatusType;

  @Watch("getDeleteBannerLoadingStatus", { immediate: true })
  getDeleteBannerLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      this.Cancel();
    }
  }
  @Watch("isShowModal")
  showModalChanged(newVal: boolean) {
    if (!newVal) {
      this.Cancel();
    }
  }

  public Cancel() {
    this.$emit("update:isShowModal", false);
  }
  public Confirm() {
    this.DoDeleteBanner({ code: this.code, name: this.name });
    alert("เรียบร้อย รายการจะถูกลบจากระบบเวลา 24:00 น");
    location.reload();
  }
}
