


























































































import ModalDeleteLatestUpdate from "@/components/modal/latestUpdate/ModalDeleteLatestUpdate.vue";
import ModalOpenPreview from "@/components/modal/latestUpdate/ModalOpenPreview.vue";
import { LoadingStatusType, BannerOpenType } from "@/enums/enums";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreLatestUpdate = namespace("LatestUpdate");
@Component({
  components: {
    ModalDeleteLatestUpdate,
    ModalOpenPreview,
  },
})
export default class DetailLatestUpdate extends Vue {
  @StoreLatestUpdate.Action
  private DoGetBannerDetail!: (code: string) => void;
  @StoreLatestUpdate.Getter
  private getBannerDetailLoadingStatus!: LoadingStatusType;
  @StoreLatestUpdate.Getter
  private getBannerDetail!: any | null;
  private bannerDetail: any = null;
  private BannerOpenType = BannerOpenType;
  private code = "";
  private isShowDeleteLatestUpdateModal = false;
  private bannerTypeText = ["None", "Modal", "Link"];
  private previewLang = "th";
  private isShowOpenPreviewModal = false;
  private openModalData: any = null;
  private isCheckButton = false;
  private destinationURL = "";
  created() {
    this.GetBannerDetail();
  }
  @Watch("getBannerDetailLoadingStatus", { immediate: true })
  getBannerDetailLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      if (this.getBannerDetail) {
        this.bannerDetail = this.getBannerDetail;
      }
    }
  }
  public GetBannerDetail() {
    const emtStr: any = this.$route.query.code;
    this.code = emtStr;
    this.DoGetBannerDetail(this.code);
  }
  public RedirectToLatestUpdateTable() {
    window.location.href = "/latestUpdate";
  }
  public Delete() {
    this.isShowDeleteLatestUpdateModal = true;
  }
  public ShowPreview(lang: string) {
    this.previewLang = lang;
    const filterContentTH = this.bannerDetail.contents.find((item: any) => item.language === "th");
    const filterButtonTH = this.bannerDetail.buttons.find((item: any) => item.language === "th");
    const bannerDetailTH = { ...filterContentTH, ...filterButtonTH };
    const filterContentEN = this.bannerDetail.contents.find((item: any) => item.language === "en");
    const filterButtonEN = this.bannerDetail.buttons.find((item: any) => item.language === "en");
    const bannerDetailEN = { ...filterContentEN, ...filterButtonEN };
    this.openModalData = {
      headerTH: bannerDetailTH.title,
      headerEN: bannerDetailEN.title,
      contentTH: bannerDetailTH.content,
      contentEN: bannerDetailEN.content,
      buttonNameTH: bannerDetailTH.text,
      buttonNameEN: bannerDetailEN.text,
      mediaTH: bannerDetailTH.mainMediaType,
      mediaEN: bannerDetailEN.mainMediaType,
      imageTH: bannerDetailTH.mainMediaUrl,
      imageEN: bannerDetailEN.mainMediaUrl,
      youtubeURLTH: bannerDetailTH.mainMediaUrl,
      youtubeURLEN: bannerDetailEN.mainMediaUrl,
    };
    this.isCheckButton = this.bannerDetail.buttons.length > 0;
    this.isShowOpenPreviewModal = true;
  }
  public EditBanner() {
    const routeData = this.$router.resolve({
      name: "editLatestUpdate",
      query: { code: this.bannerDetail.code },
    });
    window.open(routeData.href, "_blank");
  }
}
