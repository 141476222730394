




























import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { BannerMediaType } from "../../../enums/enums";

@Component
export default class ModalOpenPreview extends Vue {
  @Prop({ required: true }) readonly openModalData!: any;
  @Prop({ required: true }) readonly previewLang!: any;
  @Prop({ required: true }) readonly destinationURL!: any;
  @Prop({ required: true }) readonly isCheckButton!: any;
  private isShowModal = true;
  private imageUrl = "";
  private iframe = "";

  @Watch("isShowModal")
  showModalChanged(newVal: boolean) {
    if (!newVal) {
      this.Close();
    }
  }
  public GenerateImageUrl(file: any) {
    if (file) {
      this.imageUrl = file.originalImage.src;
    }
  }
  public CheckMediaTypeImage() {
    if (this.previewLang === "th" && this.openModalData.mediaTH === BannerMediaType.Image) {
      if (typeof this.openModalData.imageTH !== "string") {
        this.GenerateImageUrl(this.openModalData.imageTH);
      } else {
        this.imageUrl = this.openModalData.imageTH;
      }
      return true;
    } else if (this.previewLang === "en" && this.openModalData.mediaEN === BannerMediaType.Image) {
      if (typeof this.openModalData.imageTH !== "string") {
        this.GenerateImageUrl(this.openModalData.imageEN);
      } else {
        this.imageUrl = this.openModalData.imageEN;
      }
      return true;
    }
    return false;
  }
  SetIframe(youtubeLink: string) {
    let urlObj = new URL(youtubeLink);
    let params = new URLSearchParams(urlObj.search);
    let videoId = params.get("v");
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    this.iframe = `<iframe width="680" height="400" src="${embedUrl}" title="YouTube video player" frameborder="0"  clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  }
  public CheckMediaTypeVideo() {
    if (this.previewLang === "th" && this.openModalData.mediaTH === BannerMediaType.Link) {
      this.SetIframe(this.openModalData.youtubeURLTH);
      return true;
    } else if (this.previewLang === "en" && this.openModalData.mediaEN === BannerMediaType.Link) {
      this.SetIframe(this.openModalData.youtubeURLEN);
      return true;
    }
    return false;
  }

  public Close() {
    this.$emit("update:isShowModal", false);
  }
  public OpenLink() {
    window.open(this.destinationURL, "_blank");
  }
}
